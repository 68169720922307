@mixin mobile {
  @media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
    @content
  }

}

body {
  background-color: #f9f9f9;
  font-family: 'Roboto', sans-serif;
}

.img_link {
  height: 100%;
}

.datagib_container {
  min-width: 1100px;
  max-width: 1800px;

  @include mobile {
    min-width: inherit;
    max-width: inherit;
    width: 100%;
  }
}

.title {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 75px;
  margin-bottom: 20px;
  color: #9d8f8f;

  @include mobile {
    font-size: 50px;
    margin-top: 25px;
  }
}

.subtitle {
  @extend .title;
  font-size: 17px;
  margin-bottom:45px;

  @include mobile {
    font-size: 22px;
    line-height: 30px;
  }
}

.subtext {
  @extend .subtitle;
  text-align: center;
  margin-bottom: 5px;
  font-style: italic;
  font-size: 14px;

  @include mobile {
    margin: 10px auto 10px auto;
    line-height: 23px;
  }
}

input {
  border: 0;
  outline: none;
  box-sizing: border-box;
  border-radius: 3px;
}

.datagib_content {
  margin: 10% auto 100px auto;
  width: 50%;

  @include mobile {
    text-align: center;
    margin: auto;
    width: 90%;
  }
}

.result_box {
  display: flex;
}

.result {
  transition: transform 200ms;
  &:hover {
    cursor: pointer;
    transform: scale(1.05, 1.05);
  }
  margin: 10px 0 15px 0;
  display: flex;
  height: 70px;
  background-color: #f9f9f9;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
  font-size: 13px;
  color: #9d8f8f;
  border: 1px solid #9d8f8f;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 4px 5px 8px 0 rgba(157, 143, 143, 0.39);

  @include mobile {
    font-size: 20px;
    transition: none;
    height: 120px;

    &:hover {
      transform: none;
    }
  }
}

.result__commit {
  padding: 10px;
  overflow: hidden;
}

.result__commit_info {
  font-size: 11px;

  @include mobile {
    font-size: 12px;
    line-height: 22px;
  }
}

.result__message {
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 10px;
}

.query_box {
  margin-bottom: 40px;
  //background-color: #bcbab8;
  //height: 80px;
  font-size: 40px;
  color: #f9f9f9;
  transition: 0.25s;
  padding: 10px;
  width: 100%;
  background-color: #9d8f8f;
  box-shadow: 5px 5px 40px #bcbcbc;
  height: 80px;

  &:focus, &:hover {
    height: 90px;
  }

  @include mobile {
    font-size: 25px;
    height: 40px;
    max-width: 500px;

    &:focus, &:hover {
      height: 70px;
    }
  }
}

.avatar {
  height: 100%;

  @include mobile {
    display: none;
  }
}

.repo_link, .user_link {
  font-weight: 600;
  text-decoration: underline;
}

.spinner {
  display: flex;
  margin: auto;
  width: 85px;
  height: 84px;
  text-align: center;
  font-size: 8px;
  align-items: center;
  justify-content: space-evenly;
}

.spinner > div {
  background-color: #9d8f8f;
  height: 100%;
  width: 12px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
       transform: scaleY(1.0);
       -webkit-transform: scaleY(1.0);
     }
}
